import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {HomePage} from '../home/home.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from '../../../environments/environment';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {firstOfObservable, numberD, numberToCurrency, sleep} from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['payment-success.page.scss']
})
export class PaymentSuccessPage extends RepositoryDirective implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	OrderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	orderId: string;
	environment = environment;
	numberToCurrency = numberToCurrency;
	loading = true;
	tip: number = 0;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	static async navigate(router: Router, order: string[], payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order
			}
		});
	}

	ionViewDidEnter() {
		console.log(this.verifiedOrder);
		this.repository.order.emit(null);
	}

	async onInitFinish() {
		super.onInitFinish();
		this.route.queryParams.subscribe(params => {
			console.log({
				params
			});
			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
	}

	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		this.loading = true;
		if (attempt > 10) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000
			});
			this.loading = false;
			return;
		}
		if (attempt > 0) {
			await sleep(200);
		}
		try {
			const orderResponse = await Api.getOrder(this.orderId);
			this.repository.verifiedOrder.emit(orderResponse.data);
			if (!this.verifiedOrder.isPayed) {
				await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
				return;
			}
			const params = await firstOfObservable(this.route.params);
			if (params.payment) {
				const payment = (await Api.getPayment(params.payment)).data;
				this.tip = numberD(payment.tip);
			}
			this.analytics.paymentSuccess();
			try {
				if (this.venue && this.venue._id === this.verifiedOrder.venue) {
					this.orderVenue = this.venue;
				} else {
					this.orderVenue = (await Api.getLazyVenue(this.verifiedOrder.venue)).data;
				}
				this.error = false;
				this.loading = false;
			} catch (e) {
				console.error(e);
				await this.reloadOrder(attempt + 1, error ? error : e);
				return;
			}
		} catch (e) {
			console.error(e);
			await this.reloadOrder(attempt + 1, e);
			return;
		}
	}

	home() {
		if (this.loading) {
			return;
		}
		if (this.error) {
			this.reloadOrder();
			return;
		}
		this.loading = true;
		HomePage.navigate(this.router, this.repository);
	}

	openReviewHyperlink(venue: Venue) {
		try {
			console.log(venue);
			const url = (venue as Venue & {reviewHyperlink: string}).reviewHyperlink;

			window.open(url, '_blank');
		} catch (error) {
			console.log(error);
		}
	}
}
