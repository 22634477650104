import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {
	defaultsToArticleOption,
	getBasePrice,
	getPrice,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';
	numberToCurrency = numberToCurrency;
	getBasePrice = getBasePrice;
	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(this.article, [], this.article.defaults, this.preorderType);
			articleGroup.quantity = 1;
			this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.getArticlePrice(this.article, this.price), this.currency);
	}

	getArticlePrice(article: Article, price: number) {
		let totalPrice = price;
		const tagPrice = this.findTagPrice(article);
		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(
				it => it?.identifier === 'deposit_25' || it?.identifier === 'deposit_15' || it?.identifier === 'deposit_8'
			);
			if (foundTag) {
				return foundTag.identifier === 'deposit_25'
					? 0.25
					: foundTag.identifier === 'deposit_15'
					  ? 0.15
					  : foundTag.identifier === 'deposit_8'
					    ? 0.8
					    : 0;
			}
		} else {
			return null;
		}
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15') ? 'deposit15' : '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8') ? 'deposit8' : '';

		return deposit15 || deposit25 || deposit8;
	}

	showMeasurement(article: Article) {
		if (article) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					return (
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					return (
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency)
					);
				}
				return (
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency)
				);
			}
		} else {
			return '';
		}
	}
}
