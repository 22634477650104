import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import {getAvailability, getPrice, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-multiple',
	templateUrl: './article-option-group-multiple.component.html',
	styleUrls: ['article-option-group-multiple.component.scss']
})
export class ArticleOptionGroupMultipleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	ot = OrderType;
	di = DisplayIdentifier;

	constructor() {}

	increaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = 1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	decreaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = -1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	count(article: Article): number {
		return this.selected
			.filter(articleOption => articleOption.article._id === article._id)
			.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	totalCount(): number {
		return this.selected.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	hasEmptyTag(optionArticle: Article): boolean {
		return TagUtils.hasEmptyTag(optionArticle);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15') ? 'deposit15' : '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8') ? 'deposit8' : '';

		return deposit15 || deposit25 || deposit8;
	}
}
