import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const hausmannsCustomerGroup = 'hausmanns';
export const customerGroup = hausmannsCustomerGroup;

const supportEmail = {
	hausmanns: 'hausmanns@smoothr.de'
};
const firebaseConfig = {
	hausmanns: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:68d382234f8685c3e0fe06',
		measurementId: 'G-MEQ129C0GS'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAUJ0yCbuGUs9lDKehj-VGlD0oeU3XO__A',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	allergens_link: 'https://hausmanns-restaurant.com/wp-content/uploads/2023/09/HM_Allergen-Uebersicht_FOOD23.pdf',
	...apiEnvironment,
	hasRecommendations: true,
	hjid: 5035140
};
